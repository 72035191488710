import React from "react";

function Photo() {
    return (
        <div>
            <img src="/Ebonom.png" alt="profile" className="profile-img"></img>
        </div>
    );
}

export default Photo;
